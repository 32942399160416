import { Component, OnInit } from '@angular/core';
import { SepomexService } from '../services/sepomex.service';

@Component({
  selector: 'app-migrar-sepomex',
  templateUrl: './migrar-sepomex.component.html',
  styleUrls: ['./migrar-sepomex.component.css'],
  providers: [SepomexService]
})
export class MigrarSepomexComponent implements OnInit {

  constructor(
    private _sepomexService: SepomexService
  ) { }

  ngOnInit() {
  }

  migrarEstados(){
    this._sepomexService.getEstados().subscribe(
      response => {
        this._sepomexService.setEstados(response.response.estado).subscribe(
          response => {
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  migrarCiudades(){
    this._sepomexService.getEstadosBack().subscribe(
      response => {
        response.forEach(estado => {
          this._sepomexService.getMunicipios(estado).subscribe(
            async response => {
              await this._sepomexService.setMunicipios(estado.id, response.response.municipios).subscribe(
                async response => {
                },
                error => {
                  console.log(error);
                }
              );
            },
            error => {
              console.log(error);
            }
          );
        });
        
      },
      error => {
        console.log(error);
      }
    );
  }

}
