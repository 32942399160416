import { Component, OnInit } from '@angular/core';
import { MailService } from '../services/mail.service';
import { SessionService } from '../services/session.service';
import { Routes, RouterModule, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-restore',
  templateUrl: './password-restore.component.html',
  styleUrls: ['./password-restore.component.css'],
  providers: [MailService, SessionService]
})

export class PasswordRestoreComponent implements OnInit {

  public mail:string;
  public message:string;
  public display_form:boolean;
  public text_color:string;
  public restore_id;
  public id_user:string;
  public password:string;
  public password_check:string;
  public resp:any;

  constructor(
    public _mailService: MailService,
    public _sessionService: SessionService,
    public _route: ActivatedRoute,
    public _router : Router
  ) {
    this.id_user = '';
    this.mail = '';
    this.message = '';
    this.display_form = true;
    this.text_color = 'black';
    this.password = "";
    this.password_check = "";
  }

  ngOnInit() {
    this.restore_id = this._route.snapshot.paramMap.get('restore-id');
    if(this.restore_id){
      this._sessionService.getIdForRestore(this.restore_id).subscribe(
        response => {
          if(response == null) {
            this._router.navigate(['/home']);
          }
          else {
            this.resp = response;
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  setPassword(){
    if(this.password.length < 8){
      this.message = "La contraseña es demasiado corta";
      this.text_color = 'red';
    }
    else{
      if(this.password != this.password_check){
        this.message = "Las contraseñas no coinciden";
        this.text_color = 'red';
      }
      else{
        this.message = "";
        alert('Confirmar cambio de contraseña');
        this._sessionService.resetPassword(this.resp.id_user,this.password).subscribe(
          response => {},
          error => {
            if(error.error.text == 'OK') {
              this.message = "La contraseña se ha reestablecido exitosamente";
              this.text_color = 'black';
              this.display_form = false;
            }
            else if(error.error.text == 'ERROR'){
              this.message = "Hubo un error y no se pudo actualizar la contraseña";
              this.text_color = 'red';
            }
          }
        )
      }
    }
  }

  submit(){
    this._mailService.restorePassword(this.mail).subscribe(
      response => {
      },
      error => {
        if(error.error.text == 'OK') {
          this.message = "Se ha enviado a tu correo un enlace para el reestablecimiento de tu contraseña";
          this.text_color = 'black';
          this.display_form = false;
        }
        else if(error.error.text == 'No email found'){
          this.message = "Se ha enviado a tu correo un enlace para el reestablecimiento de tu contraseña";
          this.text_color = 'black';
          this.display_form = false;
        }
        else if(error.error.text == 'Error getting mail'){
          this.message = "Ocurrió un error interno, intente de nuevo más tarde";
          this.text_color = 'red';
        }
      }
    );
  }

}
