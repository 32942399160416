import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoffeeService } from '../services/coffee.service';
import { CartService } from '../services/cart.service';
import { SessionService } from '../services/session.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css'],
  providers: [CoffeeService, SessionService]
})
export class ProductoComponent implements OnInit {

  public coffee: Array<any>;
  public res: any;
  public sku: string;
  public seleccion: string;

  public baseApiUrl: string = environment.apiUrl;

  constructor(
    public cartService: CartService,
    public _sessionService: SessionService,
    public _route: ActivatedRoute,
    public _coffeeService: CoffeeService
  ){
    this.seleccion = 'Grano';
  }

  quantity = 1;

  carItems= [
    { img : 0, title : 'Chiapas' },
    { img : 1, title : 'Chiapas Tueste Italiano' },
    { img : 2, title : 'Guerrero' }
  ];

  ngOnInit() {
    window.scrollTo(0, 0);
    this.sku = this._route.snapshot.paramMap.get('productId');
    this._coffeeService.getCoffee(this.sku).subscribe(
      response => {
        this.coffee = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  hideModal(){
    $('#exampleModal').modal('hide');
  }

  incQuantity(){
    this.quantity++;
  }

  decQuantity(){
    if(this.quantity > 1){
      this.quantity--;
    }
  }

  addToCart(){
    this.cartService.add({key: this.coffee[0], molienda: this.seleccion, quantity : this.quantity});
    this._sessionService.checkSession(
      callback => {
        if (callback.logged){
          this.cartService.upsertCart().subscribe(
            response => {},
            error => {
              console.log(error);
            }
          );
        }
      }
    );
    setTimeout(function(){
      $('#exampleModal').modal('hide');
    }, 2500);
    this.quantity = 1;
    this.seleccion = 'Grano';
  }

  seleccionar(tipoMolienda){
    this.seleccion = tipoMolienda;
  }

}
