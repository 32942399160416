import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'contacto-panel',
  templateUrl: './contacto-panel.component.html',
  styleUrls: ['./contacto-panel.component.css']
})
export class ContactoPanelComponent implements OnInit {

  @Input() data : ContactoPanelData;
  @Output() SelectSucursal = new EventEmitter();

  constructor() { }

  ngOnInit() {
    var delayInMilliseconds = 500;
    setTimeout(function() {
      document.getElementById("13").className ="divDisabled"
    }, delayInMilliseconds);
  }

  select(event){
    this.SelectSucursal.emit({data : this.data});
  }

}
export class ContactoPanelData{
  imagen : string;
  zona : string;
  nombre : string;
  direccion : string;
  map : string;
}
