import { Component, OnInit } from '@angular/core';
import { ContactoPanelData } from '../contacto-panel/contacto-panel.component';
declare var jQuery:any;
declare var $:any;

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public all:boolean;
  public sucursal:any = {};

  panels : ContactoPanelData[] = [
    {
      imagen : '0', zona : 'Zona Centro', nombre : 'PORTAL',
      direccion : 'Portal Galeana #143, Col. Centro C.P. 58000',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.225007291058!2d-101.19501398544043!3d19.703042337083456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfd6d79d4d6ac1676!2sCaf%C3%A9%20Europa%20Portal!5e0!3m2!1ses-419!2smx!4v1599259914753!5m2!1ses-419!2smx"
    },
    {
      imagen : '1', zona : 'Zona Centro', nombre : 'SAN FRANCISCO',
      direccion : 'Bartolomé de las Casas #97, Col. Centro C.P. 58000',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10624.131688765661!2d-101.19355013971138!3d19.70420200569353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xadcae3c257bcb239!2sCafe%20Europa!5e0!3m2!1ses-419!2smx!4v1599263652561!5m2!1ses-419!2smx"
    },
    {
      imagen : '2', zona : 'Zona Oriente', nombre : 'LA CABAÑA',
      direccion : 'Av. Enrique Ramírez #500, Fracc. Las Américas C.P. 58270',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15025.686042440164!2d-101.16372204311844!3d19.69467068893658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d120158d8668d%3A0xbfefee3d8e1b7b2c!2sCafe%20Europa%20Restaurante!5e0!3m2!1ses-419!2smx!4v1599264086156!5m2!1ses-419!2smx"
    },
    {
      imagen : '3', zona : 'Zona Oriente', nombre : 'GALERÍA',
      direccion : 'Av. Enrique Ramírez. #420, Col. Chapultepec Oriente C.P. 58260',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15025.68228942506!2d-101.16372204571067!3d19.694710669563154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d1200edbc7c63%3A0x1d786e63ae8cbcb4!2sCaf%C3%A9%20Europa!5e0!3m2!1ses-419!2smx!4v1599264220260!5m2!1ses-419!2smx"
    },
    {
      imagen : '4', zona : 'Zona Oriente', nombre : 'ISLA',
      direccion : 'Av. Enrique Ramírez #1000 Int. Isla 10, Fracc. Las Américas C.P. 58270',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15026.288385414715!2d-101.16734504958951!3d19.68825295778022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x47f7fd7553fd61e!2sCaf%C3%A9%20Europa!5e0!3m2!1ses-419!2smx!4v1599264295876!5m2!1ses-419!2smx"
    },
    {
      imagen : '5', zona : 'Zona Oriente', nombre : 'LAS AMÉRICAS',
      direccion : 'Av. Enrique Ramírez #1000 Int. 236, Fracc. Las Américas C.P. 58270',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15026.288385414715!2d-101.16734504958951!3d19.68825295778022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x47f7fd7553fd61e!2sCaf%C3%A9%20Europa!5e0!3m2!1ses-419!2smx!4v1599264295876!5m2!1ses-419!2smx"
    },
    {
      imagen : '6', zona : 'Zona Sur', nombre : 'ALTOZANO',
      direccion : 'Av. Montaña Monarca #1000 Local 1N-49A, Col. Desarrollo Montaña Monarca C.P. 58090',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.8780466663447!2d-101.1644551854408!3d19.675207637977135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0dfff9657a8b%3A0x3a495672f977e20!2sCaf%C3%A9%20Europa%20Altozano!5e0!3m2!1ses-419!2smx!4v1599264393020!5m2!1ses-419!2smx"
    },
    {
      imagen : '7', zona : 'Zona Sur', nombre : 'BOULEVARD',
      direccion : 'Blvd. Rafael García de León #932, Col. Nueva Chapultepec C.P. 58280',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15026.183044593909!2d-101.1783598003587!3d19.689375468374518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x53d70ed9998b0c5b!2sCaf%C3%A9%20Europa!5e0!3m2!1ses-419!2smx!4v1599264456032!5m2!1ses-419!2smx"
    },
    {
      imagen : '8', zona : 'Zona Sur', nombre : 'EXPENDIO',
      direccion : 'Av. Camelinas Esq. Ventura Puente #1843 Local G-17, Col. Electricistas C.P. 58290',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12051.036825959318!2d-101.18779056956724!3d19.684355969522407!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeeb1ac6c964feba!2sCaf%C3%A9%20Europa!5e0!3m2!1ses-419!2smx!4v1606681649760!5m2!1ses-419!2smx"
    },
    {
      imagen : '9', zona : 'Zona Sur', nombre : 'PATIO',
      direccion : 'Av. Ventura Puente #1843 Int E-9, Col. Electricistas C.P. 58290',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.7101247123856!2d-101.18259088544073!3d19.68236863774733!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0de620981955%3A0x541bfe460b5acbd6!2sCafe%20Europa!5e0!3m2!1ses-419!2smx!4v1599264526868!5m2!1ses-419!2smx"
    },
    {
      imagen : '10', zona : 'Zona Poniente', nombre : 'WALMART',
      direccion : 'Calzada la Huerta #3000 Int. 1, Col. Exhacienda La Huerta C.P. 58089',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.778027495754!2d-101.22205978544078!3d19.6794732378403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0c3ac968c59b%3A0x9aa301c98f3f6db6!2sCaf%C3%A9%20Europa%20Walmart!5e0!3m2!1ses-419!2smx!4v1599264612563!5m2!1ses-419!2smx"
    },
    {
      imagen : '11', zona : 'Zona Poniente', nombre : 'PLAZA U',
      direccion : 'Av. Universidad #1755 Local 1, Col. Real Universidad C.P. 58060',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.602038331682!2d-101.21273808544068!3d19.68697663759952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0da54d703bf7%3A0x8ac71291f2a99f52!2sPlaza%20U!5e0!3m2!1ses-419!2smx!4v1599264648548!5m2!1ses-419!2smx"
    },
    {
      imagen : '12', zona : 'Zona Sur', nombre : 'CENTRO CAPITAL',
      direccion : 'Av. Tres Marías #405, Col. Tres Marías, C.P. 58254',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.217547340107!2d-101.11715488508902!3d19.70336008673102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d117f51c88bcf%3A0x8c00f065e529a990!2sAv.%20Tres%20Marias%2C%20Michoac%C3%A1n!5e0!3m2!1ses-419!2smx!4v1603152116340!5m2!1ses-419!2smx"
    },
    {
      imagen : '13', zona : 'MT1', nombre : 'ESPRESSO BAR',
      direccion : 'Contrátalo para tus eventos. Whatsapp: 443 242 6371',
      map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3756.217547340107!2d-101.11715488508902!3d19.70336008673102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d117f51c88bcf%3A0x8c00f065e529a990!2sAv.%20Tres%20Marias%2C%20Michoac%C3%A1n!5e0!3m2!1ses-419!2smx!4v1603152116340!5m2!1ses-419!2smx"
    }
  ];

  constructor(public translate: TranslateService) {
    //translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.all = true;
    window.scrollTo(0, 0);
  }

  select(event){
    this.sucursal = event.data;
    this.all = false;
    (<HTMLImageElement>document.getElementById('iframe')).src = this.sucursal.map;
    var ele = document.getElementById('iframe');
    window.scrollTo(ele.offsetLeft,ele.offsetTop);
    //window.scrollTo("#iframe");
  }

}
