import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ConektaService {

  private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ) { }

    pay(userData: any, sendPrice: number, items: string, token: any, address: any): Observable<any> {
      return this._http.post(`${this._baseApiUrl}/conekta/pay`,{userData, sendPrice, items, token, address});
    }

    oxxo_pay(userData: any, sendPrice: number, items: string, address: any): Observable<any> {
      return this._http.post(`${this._baseApiUrl}/conekta/oxxo_pay`, {userData, sendPrice, items, address});
    }

}
