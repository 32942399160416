import { filter } from 'rxjs/operators'
import { Router, NavigationEnd } from '@angular/router';
import { Component } from '@angular/core';

declare var gtag;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private router: Router){
    const navEndEvents$= this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents$.subscribe((event:NavigationEnd) => {
      gtag('config', 'UA-173671217-1',{
        'page_path': event.urlAfterRedirects
      });
    })
  }
  title = 'cafe-europa';
}
