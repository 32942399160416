import { Component, OnInit } from '@angular/core';
import { HomePanelData } from '../home-panel/home-panel-data';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  panels : HomePanelData[] = [
    {
      image : 0, imageColumnOrder : 0, textColumnOrder: 1, textAlign : 'l', title : 'El Cafetal', margin : 'bottom',
      subtitle : 'La cadena productiva del café nos hace conscientes de que formamos parte de grandes esfuerzos, que inician desde nuestras tierras, en el cafetal donde se cuida cada fruto que crece en la planta llamada cafeto.'
    },
    {
      image : 1, imageColumnOrder : 1, textColumnOrder: 0, textAlign : 'r', title : 'La cosecha', margin : 'bottom',
      subtitle : 'Es un proceso totalmente manual, en el que las cerezas maduras se seleccionan y recolectan una a una, garantizando una cosecha de alta calidad.'
    },
    {
      image : 2, imageColumnOrder : 0, textColumnOrder: 1, textAlign : 'l', title : 'Beneficiado', margin : 'bottom',
      subtitle : 'Es el proceso en el que se transforma la cereza del café a café verde, los más comunes son lavado, natural y enmielado.'
    },
    {
      image : 3, imageColumnOrder : 1, textColumnOrder: 0, textAlign : 'r', title : 'Tueste', margin : 'bottom',
      subtitle : 'Es el proceso en el que los granos de café desarrollan sus sabores y aromas mediante la temperatura y tiempo dentro del tostador.'
    },
    {
      image : 4, imageColumnOrder : 0, textColumnOrder: 1, textAlign : 'l', title : 'Método', margin : 'bottom',
      subtitle : 'La preparación utilizada por el barista puede ser en una máquina espresso o alguna otra cafetera, por lo que es importante la especialización en obtener la mejor extracción a partir de los sólidos del café.'
    },
    {
      image : 5, imageColumnOrder : 1, textColumnOrder: 0, textAlign : 'r', title : 'Taza', margin : 'none',
      subtitle : 'El resultado final es la extracción líquida del café, la cual se convierte en una experiencia sensorial, el aroma y sabor en cada taza refleja el esfuerzo y respeto de cada una de las personas que intervienen en esta cadena de valor.'
    }
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
