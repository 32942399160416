import { Component, OnInit } from '@angular/core';
import { FichaOxxoComponent } from '../ficha-oxxo/ficha-oxxo.component'
import { CartService } from '../services/cart.service';
import { FedexService } from '../services/fedex.service';
import { ConektaService } from '../services/conekta.service';
import { SessionService } from '../services/session.service';
import { SepomexService } from '../services/sepomex.service';
import { MailService } from '../services/mail.service';
import { PayPalService } from '../services/paypal.service';
import { Routes, RouterModule, Router } from '@angular/router';
import { Conektaa } from 'conekta-card-tokenizer';
import { environment } from '../../environments/environment';
declare var jQuery:any;
declare var $:any;
declare var paypal: any;

declare var Conekta: any;

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css'],
  providers: [FedexService, ConektaService, SessionService, SepomexService, MailService, PayPalService]
})
export class PedidoComponent implements OnInit {

  public card: any;

  public buttons_blocked: boolean;

  public oxxo_reference;

  public charging_delivery = false;
  public credit_card = true;

  public loading_States: boolean;

  public items;
  public addresses:any = [];
  public address:any = [];
  public total: number;
  public logged:boolean;
  public sendPrice:number = 0;
  public confirm = true;
  public userData:any;
  public dataFilled: boolean = false;

  public select_address:boolean;

  public estados: any;
  public municipios: any;
  public cps: any;
  public colonias: any;

  public state_disabled: boolean;
  public city_disabled: boolean;
  public cp_disabled: boolean;
  public neighbourhood_disabled: boolean;

  public pay_with_paypal = false;

  public baseApiUrl: string = environment.apiUrl;

  constructor(
    public cartService: CartService,
    public _fedexService: FedexService,
    public _conetkaService: ConektaService,
    public _sessionService: SessionService,
    public _sepomexService: SepomexService,
    public _mailService: MailService,
    public _paypalService: PayPalService,
    public _router : Router
  ) {
    this.buttons_blocked = false;
    this.oxxo_reference = '';
    this.logged = false;
    this.card = {
      card_name : '',
      card_number : '',
      card_cvv : '',
      card_month : '',
      card_year : ''
    }
    this.userData = {
      user_id : 0,
      user_name : '',
      user_mail : '',
      user_phone : ''
    }
    this.state_disabled = true;
    this.address = {};
    this.city_disabled = true;
    this.cp_disabled = true;
    this.neighbourhood_disabled = true;
    this.select_address = false;

    this.items = this.cartService.getItems();
    this.getTotal();
    this._sessionService.checkSession(
      callback => {
        this.logged = callback.logged;
        if(this.logged){
          this.getAddresses();
        }
        else{
          this.getEstados();
        }
      }
    );
    if (this.sendPrice == 0) this.confirm = true;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  fillData(){
    this.dataFilled = (this.userData.user_name != "" && this.userData.user_mail != "" && this.userData.user_phone != "" ? true : false)
  }

  change_payment(){
    if (this.credit_card) {
      paypal.Buttons({
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '0.01'
              }
            }]
          });
        }
      }).render('#paypal-button-container');
      this.credit_card = false;
    }
    else this.credit_card = true;
  }

  check_tel(){
    var valid = true;
    for(var i = 0; i < this.userData.user_phone.length; i++){
      var l = this.userData.user_phone.charAt(i);
      if (l != 0 && l != 1 && l != 2 && l != 3 && l != 4 && l != 5 && l != 6 && l != 7 && l != 8 && l != 9){
        valid = false;
      }
    }
    if(!valid){
      alert('Teléfono inválido, vuelva a llenarlo');
      this.userData.user_phone = '';
    }
    else{
      if(this.userData.user_phone.length != 10) {
        alert('El teléfono debe contener 10 dígitos');
        this.userData.user_phone = '';
      }
    }
  }

  getAddresses() {
    this.loading_States = true;
    this.cartService.getAddresses().subscribe(
      response => {
        if(response && response.length !== 0){
          this.addresses = response;
          this.address = this.addresses[0];
          if(this.addresses.length != 0) {
            this.select_address = true;
            this.calculatePrice();
          }
          else this.getEstados();
        }
        else{
          this.getEstados();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  calculatePrice(){
    $('#exampleModal2').modal('show');
    this.charging_delivery = true;
    var parser, xmlDoc;
    parser = new DOMParser();
    var presentation;
    var weight = 0;
    this.items.forEach(item => {
      if (item.key.coffee_presentation < 100) presentation = item.key.coffee_presentation;
      else presentation = item.key.coffee_presentation/1000;
      weight += presentation * item.quantity;
    });
    this._fedexService.getRate(this.address, weight).subscribe(
      response => {
        if(response.response == 'ERROR'){
          alert('Hubo un error calculando el precio de envío, intente de nuevo más tarde');
        }
        else {
          this.sendPrice = parseFloat(response.response);
          var totalPrice = (this.total+this.sendPrice).toFixed(2)+'';
          var self = this;
          document.getElementById('paypal-button-container').innerHTML = '';
          paypal.Buttons({
            createOrder: function(data, actions) {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: totalPrice
                  }
                }],
                application_context: {
                  shipping_preference: 'NO_SHIPPING'
                }
              });
            },
            onApprove: function(data, actions) {
              return actions.order.capture().then(function(details) {
                self.paypal_payment(details);
              });
            }
          }).render('#paypal-button-container');
        }
        this.charging_delivery = false;
        $('#exampleModal2').modal('hide');
      },
      error => {
        console.log(error);
        this.charging_delivery = false;
        $('#exampleModal2').modal('hide');
      }
    );
    /*this._fedexService.checkAddress(this.address).subscribe(
      response => {
        if(response.response == 'SUCCESS'){
          var presentation;
          var weight = 0;
          this.items.forEach(item => {
            if (item.key.coffee_presentation < 100) presentation = item.key.coffee_presentation;
            else presentation = item.key.coffee_presentation/1000;
            weight += presentation * item.quantity;
          });
          this._fedexService.getRate(this.address, weight).subscribe(
            response => {
              if(response.response == 'ERROR'){
                alert('Hubo un error calculando el precio de envío, intente de nuevo más tarde')
              }
              else {
                this.sendPrice = parseFloat(response.response);
                if (this.sendPrice != 0) this.confirm = false;
              }
            },
            error => {
              console.log(error);
            }
          );
        }
        else{
          alert('La dirección es inválida, favor de revisarla')
        }
      },
      error => {
        console.log(error);
      }
    );*/

  }

  showAddress(value: string){
    this.addresses.forEach(address => {
      if(address.id == value) {
        this.address = address;
        this.calculatePrice();
      }
    });
  }

  getTotal(){
    this.total=0;
    this.items.forEach(item => {
      this.total += item.quantity*item.key.coffee_price;
    });
  }

  pay(){
    this.buttons_blocked = true;
    $('#exampleModal').modal('show');
    var id = localStorage.getItem('session_id');
    if (typeof(id) != 'undefined' && id != null && id != ''){
        this._sessionService.getUserData().subscribe(
          response => {
            this.userData = response;
            this.payment();
          },
          error => {
            console.log(error);
          }
        );
    }
    else {
      this.payment()
    }
  }

  paypal_payment(details){
    $('#exampleModal').modal('show');
    this.buttons_blocked = true;
    var id = localStorage.getItem('session_id');
    if (typeof(id) != 'undefined' && id != null && id != ''){
        this._sessionService.getUserData().subscribe(
          response => {
            this.userData = response;
            this._paypalService.pay(details, this.userData, this.sendPrice, this.items, this.address).subscribe(
              response => {
              },
              error => {
                if(error.error.text != 'ERROR'){
                  var clear = this.cartService.clear();
                  this._mailService.send(error.error.text).subscribe(
                    response => {
                    },
                    error => {
                      console.log(error);
                    }
                  );
                  var self = this;
                  document.getElementById("exampleModalLabel").innerHTML = "El pago concluyó de forma exitosa, los detalles llegarán a tu correo";
                  setTimeout(function(){
                    $('#exampleModal').modal('hide');
                    self._router.navigate(['/tus-compras/'+error.error.text]);
                  }, 3000);
                }
                else {
                  $('#exampleModal').modal('hide');
                  this.buttons_blocked = false;
                  console.log(error);
                }
              }
            );
          },
          error => {
            $('#exampleModal').modal('hide');
            this.buttons_blocked = false;
            console.log(error);
          }
        );
    }
    else {
      this._paypalService.pay(details, this.userData, this.sendPrice, this.items, this.address).subscribe(
        response => {
        },
        error => {
          if(error.error.text != 'ERROR'){
            var clear = this.cartService.clear();
            this._mailService.send(error.error.text).subscribe(
              response => {
              },
              error => {
                console.log(error);
              }
            );
            var self = this;
            document.getElementById("exampleModalLabel").innerHTML = "El pago concluyó de forma exitosa, los detalles llegarán a tu correo";
            setTimeout(function(){
              $('#exampleModal').modal('hide');
              self._router.navigate(['/tus-compras/'+error.error.text]);
            }, 3000);
          }
          else{
            $('#exampleModal').modal('hide');
            this.buttons_blocked = false;
            console.log(error);
          }
        }
      );
    }
  }

  oxxo_payment(){
    $('#exampleModal').modal('show');
    this.buttons_blocked = true;
    var id = localStorage.getItem('session_id');
    if (typeof(id) != 'undefined' && id != null && id != ''){
        this._sessionService.getUserData().subscribe(
          response => {
            this.userData = response;
            this._conetkaService.oxxo_pay(this.userData,this.sendPrice,JSON.stringify(this.items),this.address).subscribe(
              response => {
                var clear = this.cartService.clear();
                this._mailService.sendOxxo(response.id, response.charges.data[0].payment_method.reference).subscribe(
                  response => {
                  },
                  error => {
                    console.log(error);
                  }
                );
                var self = this;
                $('#exampleModal').modal('hide');
                $('#oxxoModal').modal('show');
                this.oxxo_reference = response.charges.data[0].payment_method.reference;
                setTimeout(function(){
                  $('#oxxoModal').modal('hide');
                  self._router.navigate(['/tus-compras/'+response.id]);
                }, 10000);
              },
              error => {
                $('#exampleModal').modal('hide');
                this.buttons_blocked = false;
                console.log(error);
              }
            );
          },
          error => {
            $('#exampleModal').modal('hide');
            this.buttons_blocked = false;
            console.log(error);
          }
        );
    }
    else {
      this._conetkaService.oxxo_pay(this.userData,this.sendPrice,JSON.stringify(this.items),this.address).subscribe(
        response => {
          var clear = this.cartService.clear();
          this._mailService.sendOxxo(response.id, response.charges.data[0].payment_method.reference).subscribe(
            response => {
            },
            error => {
              console.log(error);
            }
          );
          var self = this;
          $('#exampleModal').modal('hide');
          $('#oxxoModal').modal('show');
          setTimeout(function(){
            $('#oxxoModal').modal('hide');
            self._router.navigate(['/tus-compras/'+response.id]);
          }, 10000);
        },
        error => {
          $('#exampleModal').modal('hide');
          this.buttons_blocked = false;
          console.log(error);
        }
      );
    }
  }

  payment(){
    Conekta.setPublicKey("key_QAqgYRWTwj19CZYoGyHs1cA");
    var tokenParams = {
        "card": {
          "number": this.card.card_number,
          "name": this.card.card_name,
          "exp_year": this.card.card_year,
          "exp_month": this.card.card_month,
          "cvc": this.card.card_cvv
        }
    };
    Conekta.Token.create(tokenParams, (token:any) => {
      const tokenId = token.id;
        this._conetkaService.pay(this.userData,this.sendPrice,JSON.stringify(this.items),tokenId,this.address).subscribe(
          response => {
            if(typeof(response.status) != 'undefined'){
              this.buttons_blocked = false;
              alert('Tarjeta inválida o con fondos insuficientes');
              $('#exampleModal').modal('hide');
            }
            else {
              var clear = this.cartService.clear();
              this._mailService.send(response.id).subscribe(
                response => {
                },
                error => {
                  console.log(error);
                }
              );
              var self = this;
              document.getElementById("exampleModalLabel").innerHTML = "El pago concluyó de forma exitosa, los detalles llegarán a tu correo";
              setTimeout(function(){
                $('#exampleModal').modal('hide');
                self._router.navigate(['/tus-compras/'+response.id]);
              }, 3000);
            }
          },
          error => {
            $('#exampleModal').modal('hide');
            this.buttons_blocked = false;
            console.log(error);
          }
        );
    }, () => {
        this.buttons_blocked = false;
        alert('Tarjeta no válida');
        $('#exampleModal').modal('hide');
    });
  }

  end_purchase(id_compra){
    $('#exampleModal').modal('hide');
    this._router.navigate(['/tus-compras/'+id_compra]);
  }

  getEstados(){
    this._sepomexService.estados().subscribe(
      response => {
        this.estados = response;
        this.state_disabled = false;
        this.loading_States = false;
        this.city_disabled = true;
        this.cp_disabled = true;
        this.neighbourhood_disabled = true;
      },
      error => {
        console.log(error);
      }
    );

  }

  getMunicipios(estado){
    $("#select_municipios").val('disabled');
    $("#select_cps").val('disabled');
    $("#select_colonias").val('disabled');
    this.sendPrice = 0;
    document.getElementById('paypal-button-container').innerHTML = '';
    this._sepomexService.municipios(estado).subscribe(
      response => {
        this.municipios = response;
        this.city_disabled = false;
        this.cp_disabled = true;
        this.neighbourhood_disabled = true;
      },
      error => {
        console.log(error);
      }
    );
  }

  getCps(municipio){
    $("#select_cps").val('disabled');
    $("#select_colonias").val('disabled');
    this.sendPrice = 0;
    document.getElementById('paypal-button-container').innerHTML = '';
    this._sepomexService.cps(municipio).subscribe(
      response => {
        this.cps = response;
        this.cp_disabled = false;
        this.neighbourhood_disabled = true;
      },
      error => {
        console.log(error);
      }
    );
  }

  getColonias(cp){
    $("#select_colonias").val('disabled');
    this.sendPrice = 0;
    document.getElementById('paypal-button-container').innerHTML = '';
    this._sepomexService.colonias(cp).subscribe(
      response => {
        this.colonias = response;
        this.neighbourhood_disabled = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  checkAddress(){
    this.calculatePrice();
  }

  change_select_address(){
    if (this.select_address) this.select_address = false;
    else this.select_address = true;
  }

  activateButton(){
    if(this.sendPrice!=0 && this.card.card_name!='' && this.card.card_number!='' && this.card.card_cvv!='' && this.card.card_month!='' && this.card.card_year!=''){
      this.confirm = false;
    }
  }
}
