import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { HomePanelComponent } from './home-panel/home-panel.component';
import { FooterComponent } from './footer/footer.component';
import { HablemosDeCafeComponent } from './hablemos-de-cafe/hablemos-de-cafe.component';
import { HablemosPanelComponent } from './hablemos-panel/hablemos-panel.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ContactoPanelComponent } from './contacto-panel/contacto-panel.component';
import { NuestroTostadorComponent } from './nuestro-tostador/nuestro-tostador.component';
import { MenuComponent } from './menu/menu.component';
import { MenuPanelEspComponent } from './menu-panel-esp/menu-panel-esp.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { TiendaComponent } from './tienda/tienda.component';
import { CarritoComponent } from './carrito/carrito.component';
import { ProductoComponent } from './producto/producto.component';
import { PedidoComponent } from './pedido/pedido.component';
import { TusComprasComponent } from './tus-compras/tus-compras.component';
import { CompraComponent } from './compra/compra.component';
import { DatosComponent } from './datos/datos.component';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'ng4-social-login';
import { MigrarSepomexComponent } from './migrar-sepomex/migrar-sepomex.component';
import { PasswordRestoreComponent } from './password-restore/password-restore.component';
import { AuthGuard } from './guard/auth.guard';
import { SessionService } from './services/session.service';
import { TerminosComponent } from './terminos/terminos.component';
import { FichaOxxoComponent } from './ficha-oxxo/ficha-oxxo.component';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('404866244078-98uecposukmv8fj914moq05ekfe5v9o4.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    //provider: new FacebookLoginProvider('725111194932458')
    provider: new FacebookLoginProvider('2627680124213517')
  }
], false);

export function provideConfig(){
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    HomePanelComponent,
    FooterComponent,
    HablemosDeCafeComponent,
    HablemosPanelComponent,
    ContactoComponent,
    ContactoPanelComponent,
    NuestroTostadorComponent,
    MenuComponent,
    MenuPanelEspComponent,
    MenuPanelComponent,
    TiendaComponent,
    CarritoComponent,
    ProductoComponent,
    PedidoComponent,
    TusComprasComponent,
    CompraComponent,
    DatosComponent,
    MigrarSepomexComponent,
    PasswordRestoreComponent,
    TerminosComponent,
    FichaOxxoComponent
  ],
  imports: [
    BrowserModule,
    SocialLoginModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: AuthServiceConfig, useFactory: provideConfig},
    AuthGuard,
    SessionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/lang/', '.json');
}
