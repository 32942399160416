import { Component, Input, OnInit } from '@angular/core';
import { HomePanelData } from './home-panel-data';

@Component({
  selector: 'home-panel',
  templateUrl: './home-panel.component.html',
  styleUrls: ['./home-panel.component.css']
})
export class HomePanelComponent implements OnInit {

  @Input() data : HomePanelData;

  constructor() {
  }

  ngOnInit() {
  }

}
