import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  items = [];
  total = 0;
  result = [];
  id_session = 1;

  private _baseApiUrl: string = environment.apiUrl;

  constructor(
    private _http: HttpClient
  ) {
    this.setCart();
  }

  add(product) {
    var added = false;
    this.items.forEach(item => {
      if(item.key.id == product.key.id && item.molienda == product.molienda){
        added = true;
        item.quantity += product.quantity;
      }
    });
    if(!added) {
      this.items.push(product);
    }
    this.total=0;
    this.items.forEach(item => {
      this.total += item.quantity;
    });
  }

  discard(id: string, molienda:string){
    var index;
    var cont = 0;
    this.items.forEach(item => {
      if(item.key.id == id && item.molienda == molienda) {
        this.total -= item.quantity;
        index = cont;
      }
      cont++;
    });
    this.items.splice(index,1);
  }

  getItems() {
    return this.items;
  }

  setItems(items) {
    this.items = items;
    this.updateCart().subscribe(
      response => {
      },
      error => {
        console.log(error);
      }
    );
    this.total=0;
    this.items.forEach(item => {
      this.total += item.quantity;
    });
  }

  clear() {
    this.items = [];
    this.total = 0;
    this.saveCart();
    return this.items;
  }

  getAddresses():Observable<any>{
    return this._http.get(`${this._baseApiUrl}/addresses/get/`+localStorage.getItem('session_id'));
  }

  saveCart():Observable<any>{
    var save = {id_user:localStorage.getItem('session_id'),cart:JSON.stringify(this.items)};
    var result = this._http.post(`${this._baseApiUrl}/carts/addCart`,save,{headers: new HttpHeaders({'Content-Type':  'application/json'})});
    result.subscribe(
      response => {
      },
      error => {
      }
    );
    return result;
  }

  updateCart():Observable<any>{
    var save = {id_user:localStorage.getItem('session_id'),cart:JSON.stringify(this.items)};
    var result = this._http.post(`${this._baseApiUrl}/carts/updateCart`,save,{headers: new HttpHeaders({'Content-Type':  'application/json'})});
    return result;
  }

  upsertCart():Observable<any>{
    var save = {id_user:localStorage.getItem('session_id'),cart:JSON.stringify(this.items)};
    var result = this._http.post(`${this._baseApiUrl}/carts/upsertCart`,save,{headers: new HttpHeaders({'Content-Type':  'application/json'})});
    return result;
  }

  setCart(){
    if(localStorage.getItem('session_id') != null && localStorage.getItem('session_id') != ''){
      this._http.post(`${this._baseApiUrl}/carts/getCart`, {id: localStorage.getItem('session_id')}).subscribe(
        async response => {
          if(response){
            if (response != 'No existe el carrito'){
              this.items = await JSON.parse(String(response));
              this.items.forEach(item => {
                this.total += item.quantity;
              });
            }
          }
          else{
          }
        },
        error => {
          console.log(error);
        }
      );
    }
  }

  getCart(): Observable<any> {
    return this._http.post(`${this._baseApiUrl}/carts/getCart`, {id: localStorage.getItem('session_id')});
  }

  getTotal() {
    return this.total;
  }

  getID_Session() {
    return this.id_session;
  }
}
