import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/home/home.component';
import { HablemosDeCafeComponent } from './hablemos-de-cafe/hablemos-de-cafe.component';
import { ContactoComponent } from './contacto/contacto.component';
import { NuestroTostadorComponent } from './nuestro-tostador/nuestro-tostador.component';
import { MenuComponent } from './menu/menu.component';
import { TiendaComponent } from './tienda/tienda.component';
import { ProductoComponent } from './producto/producto.component';
import { CarritoComponent } from './carrito/carrito.component';
import { PedidoComponent } from './pedido/pedido.component';
import { TusComprasComponent } from './tus-compras/tus-compras.component';
import { CompraComponent } from './compra/compra.component';
import { TerminosComponent } from './terminos/terminos.component';
import { DatosComponent } from './datos/datos.component';
import { MigrarSepomexComponent } from './migrar-sepomex/migrar-sepomex.component';
import { PasswordRestoreComponent } from './password-restore/password-restore.component';
import { AuthGuard } from './guard/auth.guard';
const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'hablemos-de-cafe',
    component: NuestroTostadorComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },
  {
    path: 'nuestro-tostador',
    component: HablemosDeCafeComponent
  },
  {
    path: 'menu',
    component: MenuComponent
  },
  {
    path: 'tienda/:productId',
    component: ProductoComponent
  },
  {
    path: 'tienda',
    component: TiendaComponent
  },
  {
    path: 'tus-compras',
    component: TusComprasComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tus-compras/:purchaseId',
    component: CompraComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'compra/:purchaseId',
    component: CompraComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terminos',
    component: TerminosComponent
  },
  {
    path: 'carrito',
    component: CarritoComponent
  },
  {
    path: 'pedido',
    component: PedidoComponent
  },
  {
    path: 'datos',
    component: DatosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sepomex',
    component: MigrarSepomexComponent
  },
  {
    path: 'password-restore',
    component: PasswordRestoreComponent
  },
  {
    path: 'password-restore/:restore-id',
    component: PasswordRestoreComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
