import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ficha-oxxo',
  templateUrl: './ficha-oxxo.component.html',
  styleUrls: ['./ficha-oxxo.component.css']
})
export class FichaOxxoComponent implements OnInit {

  @Input() amount = 0;
  @Input() reference = '';

  constructor() { }

  ngOnInit() {
  }

}
