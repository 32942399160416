import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'menu-panel-esp',
  templateUrl: './menu-panel-esp.component.html',
  styleUrls: ['./menu-panel-esp.component.css']
})
export class MenuPanelEspComponent implements OnInit {

  @Input() data: PanelDataEsp;

  constructor() { }

  ngOnInit() {
  }

}
export class PanelDataEsp{
  bgSize: number;
  image: string;
  title: string;
  subtitle: string;
}