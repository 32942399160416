import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class PayPalService {

  private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ) { }

    pay(details, userData, sendPrice, items, address): Observable<any> {
      return this._http.post(`${this._baseApiUrl}/paypal/pay`, {details, userData, sendPrice, items, address});
    }

}
