import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';
import { SessionService } from '../services/session.service'
import { CartService } from '../services/cart.service';
import { AuthService, SocialUser, GoogleLoginProvider, FacebookLoginProvider } from 'ng4-social-login';
import { Observable, Subscription, Subscriber } from 'rxjs';
import { error } from 'protractor';
import * as bootstrap from 'bootstrap';
//import * as $ from 'jquery';
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [SessionService]
})
export class NavbarComponent implements OnInit {

  public cartTotal: number;
  public user: any;
  public social_user: any = SocialUser;
  public hideModal: boolean;
  public sideMenuActive = false;
  public sideMenu;
  public form = "login";
  public regError = "";
  public logError = "";
  public logged:boolean;

  constructor(
    private router: Router,
    public cartService: CartService,
    private _sessionService: SessionService,
    private socialAuthService: AuthService,
    private _router : Router,
  ) {
    this.user = {
      name : '',
      lastname : '',
      mail : '',
      password : '',
      password_check : ''
    }
    this.hideModal = true;
    this._sessionService.checkSession(
      callback => {
        this.logged = callback.logged;
      }
    );
  }

  ngOnInit() {
    this.sideMenu= document.getElementById("side-menu");
    if(this.router.url.includes('carrito')){
      document.getElementById("carrito").classList.add('active-nav-item');
    }else if(this.router.url.includes('tienda')){
      document.getElementById("tienda").classList.add('active-nav-item');
      document.getElementById("tiendaSpan").classList.add('active-nav-item');
    }
    this.cartTotal = this.cartService.getTotal();
  }
  onRegister(){
    if(this.user.password == this.user.password_check){
      this._sessionService.registerUser(this.user).subscribe(
        response => {
            if(typeof(response.error) != 'undefined'){
                this.regError = "Ya existe una cuenta asociada este correo ";
                if(response.detail.substring(0,18) == 'Ya existe la llave'){
                    this.regError = "Ya existe una cuenta asociada al correo "+response.detail.substring(32,response.detail.length-2);
                }
            }
            else if(typeof(response.command) != 'undefined'){
                this.form = 'login';
            }
            else{
                alert("Error desconocido");
            }
        },
        error => {
            console.log(error);
        }
      );
    }
    else this.regError = "Las contraseñas no coinciden";
  }

  reset_password(){
    $('#modal').modal('hide');
    this._router.navigate(['/password-restore']);
  }

  onLogout(){
    this.socialAuthService.signOut();
    this._sessionService.logOut();
  }

  public changeForm(){
    switch(this.form){
      case "login": this.form = "register"; break;
      case "register": this.form = "login"; break;
    }
  }

  public setForm(){
    this.form = "login";
  }

  public showModal(){
    this.hideModal = false;
    this.toggleSideMenu();
  }

  public toggleSideMenu(){
    if(!this.sideMenuActive){
      this.sideMenu.classList.add("active");
      this.sideMenu.classList.remove("inactive");
      this.sideMenuActive = true;
    }else{
      this.sideMenu.classList.remove("active");
      this.sideMenu.classList.add("inactive");
      this.sideMenuActive = false;
    }
  }

  onLogin(){
    this._sessionService.loginUser(this.user).subscribe(
      response => {
        if (response.session_id == "ERROR"){
          this.logError = "Usuario o contraseña incorrectos";
        }
        else{
          localStorage.setItem('session_id', response.session_id);
          localStorage.setItem('session_token', response.session_token);
          this.cartService.setCart();
          this._sessionService.checkSession(
            callback => {
              this.logged = callback;
              if(this.logged) $('#modal').modal('hide');
            }
          );
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  facebookLogin(){
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) =>{
      this.social_user = userData;
      this._sessionService.loginFacebook(this.social_user).subscribe(
        response => {
            localStorage.setItem('session_id', String(response.session_id));
            localStorage.setItem('session_token', String(response.session_token));
            this.cartService.setCart();
            this._sessionService.checkSession(
              callback => {
                this.logged = callback;
                if(this.logged) $('#modal').modal('hide');
              }
            );
        },
        error => {
          console.log(error);
        }
      );
    });
  }

  public googleLogin(){
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) =>{
      this.social_user = userData;
      this._sessionService.loginGoogle(this.social_user).subscribe(
        response => {
          localStorage.setItem('session_id', String(response.session_id));
          localStorage.setItem('session_token', String(response.session_token));
          this.cartService.setCart();
          this._sessionService.checkSession(
            callback => {
              this.logged = callback;
              if(this.logged) $('#modal').modal('hide');
            }
          );
        },
        error => {
          console.log(error);
        }
      );
    });
  }

}
