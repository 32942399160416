import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestro-tostador',
  templateUrl: './nuestro-tostador.component.html',
  styleUrls: ['./nuestro-tostador.component.css']
})
export class NuestroTostadorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
