import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../services/purchase.service';
import { SessionService } from '../services/session.service';
import { Routes, RouterModule, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tus-compras',
  templateUrl: './tus-compras.component.html',
  styleUrls: ['./tus-compras.component.css'],
  providers: [PurchaseService,SessionService]
})
export class TusComprasComponent implements OnInit {

  public userData:any;
  public userResp:any;

  public flag_userData:boolean;

  public purchases:any = [];
  public envío:number;

  public baseApiUrl: string = environment.apiUrl;

  constructor(
    public _purchaseService: PurchaseService,
    public _sessionService: SessionService,
    public _router: Router
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._sessionService.checkSession(
      callback => {
        if(!callback.logged) this._router.navigate(['/home']);
        else{
          this._purchaseService.getPurchases().subscribe(
            async response => {
              this.purchases=response;
              this.purchases.forEach(purchase => {
                purchase.sendPrice = purchase.purchase_amount/100;
                purchase.purchase_items = JSON.parse(purchase.purchase_items);
                purchase.purchase_items.forEach(item => {
                  purchase.sendPrice -= (item.key.coffee_price*item.quantity);
                });
              });
            },
            error => {
              console.log(error);
            }
          );
        }
      }
    );

    this._sessionService.getUserData().subscribe(
      response => {
        this.userData = response;
        this.flag_userData = true;
      },
      error => {
        console.log(error);
      }
    );
    this.userResp = this.userData;
  }

}
