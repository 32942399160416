import { Component, OnInit } from '@angular/core';
import { PanelDataEsp } from '../menu-panel-esp/menu-panel-esp.component';
import { PanelData } from '../menu-panel/menu-panel.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  topEspPanels: PanelDataEsp[] = [
    {
      bgSize : 0,
      image : '0',
      title : 'Espresso',
      subtitle : 'Extracción pura de café con granos de nuestra mezcla de la casa, característico por su perfecto balance en acidez y dulzor, cuerpo abundante, notas a caramelo y chocolate semiamargo'
    },
    {
      bgSize : 0,
      image : '1',
      title : 'Macchiato',
      subtitle : 'Bebida corta que se compone de un espresso sencillo de la casa con una ligera cantidad de leche cremada'
    },
    {
      bgSize : 0,
      image : '2',
      title : 'Americano',
      subtitle : 'El clásico café que puedes disfrutar en cualquier momento, compuesto de agua caliente y la intensidad de espresso que más te guste'
    },
    {
      bgSize : 0,
      image : '3',
      title : 'Cappuccino Italiano',
      subtitle : 'Una bebida memorable en nuestro menú, compuesta por 1 oz de espresso y 5 oz de leche cremada como lo define la Specialty Coffee Association, con esta combinación obtenemos una bebida en la cual se destaca la presencia del espresso'
    }
  ];
  bottomEspPanels: PanelDataEsp[] = [
    {
      bgSize : 1,
      image : '4',
      title : 'Latte',
      subtitle : 'Bebida compuesta por 1 oz de espresso y 7 oz de leche cremada, el sabor dulce ocupa mayor presencia en esta bebida con un sutil sabor a espresso'
    },
    {
      bgSize : 1,
      image : '9',
      title : 'Moka',
      subtitle : 'Bebida caliente donde convive el sabor a espresso y chocolate, los cuales son mezclados con leche cremada'
    },
    {
      bgSize : 1,
      image : '6',
      title : 'Soda Espresso',
      subtitle : 'Una refrescante bebida creada por nuestra marca, compuesta por soda de lima – limón y espresso ¡Su sabor te encantará!'
    },
    {
      bgSize : 1,
      image : '8',
      title : 'Latte en las Rocas',
      subtitle : 'Bebida que se compone de 1 oz de espresso y leche fría servida sobre hielo'
    },
    {
      bgSize : 1,
      image : '5',
      title : 'Granita Cappuccino',
      subtitle : 'Sin duda esta es una de las bebidas que jamás probarás en otro lugar, es elaborada con nuestro espresso de la casa y se caracteriza por su textura granizada, el sabor a café está presente de principio a fin'
    },
    {
      bgSize : 1,
      image : '7',
      title : 'EuroLatte Caramelo',
      subtitle : 'Bebida en las rocas compuesta de espresso, leche fría y caramelo'
    }
  ];
  teaPanels: PanelData[] = [
    {
      image: '0',
      title: 'NEGRO FRUTOS ROJOS',
      subtitle: '',
      image_change: false
    },
    {
      image: '1',
      title: 'OOLONG TORONJA (AZUL)',
      subtitle: '',
      image_change: false
    },
    {
      image: '2',
      title: 'ROOIBOS CARAMELO (ROJO) ',
      subtitle: '',
      image_change: false
    },
    {
      image: '3',
      title: 'VERDE MENTA MARROQUÍ',
      subtitle: '',
      image_change: false
    }
  ];
  fruitPanels: PanelData[] = [
    {
      image: '4',
      title: 'CARICIAS DE FRUTOS',
      subtitle: 'Deliciosa combinación de Maracuyá, Mango, Uva Pasa, Papaya, Extracto de Limón y Flor de Jamaica. ',
      image_change: false
    },
    {
      image: '5',
      title: 'DULCE DESEO',
      subtitle: 'Mezcla perfecta de Fresa, Kiwi, Manzana, Betabel y Flor de Jamaica.',
      image_change: false
    },
    {
      image: '6',
      title: 'ESTALLIDO CÍTRICO',
      subtitle: 'Explosión frutal de Piña, Mango, Pera, Kiwi, Cáscara de Naranja y Pétalos de Flor de Cártamo. ',
      image_change: false
    },
    {
      image: '7',
      title: 'EXPRESIÓN DE FLORES',
      subtitle: 'Combinación de Fresa, Arándano, Zarzamora, Pétalos de Rosa, Lavanda y Pétalos de Flor de Caléndula.',
      image_change: false
    },
    {
      image: '8',
      title: 'ROSA FERVIENTE',
      subtitle: 'Fusión de Arándano, Pétalos de Rosa, Fresa, Moras y Pasas.',
      image_change: false
    }
  ];
  alternativePanels: PanelData[] = [
    {
      image: '9',
      title: 'CALIENTES, ROCAS O FRAPPES',
      subtitle: 'Chai Manzana-Canela/Chai Original/Chocolate Blanco/Chocolate Mexicano/Moka Blanco/Té Verde Matcha/Vainilla',
      image_change: true
    },
    {
      image: '10',
      title: 'FRAPPES',
      subtitle: 'Coco/Coffee Toffee/Cookies & Cream/Taro',
      image_change: true
    },
    {
      image: '11',
      title: 'SODAS ITALIANAS',
      subtitle: 'Durazno/Fresa/Kiwi/Mango/Maracuyá',
      image_change: true
    },
    {
      image: '12',
      title: 'SMOOTHIES',
      subtitle: 'Fresa/Mango',
      image_change: true
    }
  ];
  foodPanels: PanelData[] = [
    {
      image: '13',
      title: 'PASTELES Y PAYS',
      subtitle: 'Pastel de Zanahoria/Pastel de Chocolate/Pay de Queso-Zarzamora/Pay de Queso-Chocolate/Pay de Queso-Elote',
      image_change: true
    },
    {
      image: '14',
      title: 'ESPONJADITO Y CRUJIENTE',
      subtitle: 'Galleta de Avena/Polvorón/Brownie Clásico/Bigote de Chocolate/Muffin de Chocolate',
      image_change: true
    },
    {
      image: '15',
      title: 'CREPA',
      subtitle: 'Dulce/Salada',
      image_change: true
    },
    {
      image: '16',
      title: 'PANINO NATURAL',
      subtitle: 'Salami/Pepperoni/Tres Quesos',
      image_change: true
    },
    {
      image: '16',
      title: 'PANINO ORÉGANO',
      subtitle: 'Salami/Pepperoni/Tres Quesos',
      image_change: true
    },
    {
      image: '16',
      title: 'PANINO PARMESANO',
      subtitle: 'Salami/Pepperoni/Tres Quesos',
      image_change: true
    }
  ];
  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
