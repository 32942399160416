import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map} from 'rxjs/operators';
import { SessionService } from '../services/session.service';
import { environment } from '../../environments/environment';
import { createOfflineCompileUrlResolver } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  private _baseApiUrl: string = environment.apiUrl;

  constructor(private _http: HttpClient, private _router : Router, private _service : SessionService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): any {
      var response = false;
      this._service.checkSession(
        (callback: { logged: any; }) => {
          if(callback.logged){
            return true;
          }
          else {
            this._router.navigate(['/home']);
            return false;
          }
        }
      );
      return true;
    }
}
