import { Component, OnInit } from '@angular/core';
import { CoffeeService } from '../services/coffee.service';
import { Coffee } from '../models/coffee';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css'],
  providers: [CoffeeService]
})
export class TiendaComponent implements OnInit {

  public coffees: Array<any>;

  public baseApiUrl: string = environment.apiUrl;

  constructor(
    private _coffeeService: CoffeeService
  ) { }

  /*paneles = [
    {nombre : 'Chiapas',                  imagen : 0, productId : 'veracruz-tueste-americano'},
    {nombre : 'Chiapas Tueste Italiano',  imagen : 1, productId : 'veracruz-tueste-americano'},
    {nombre : 'Guerrero',                 imagen : 2, productId : 'veracruz-tueste-americano'},
    {nombre : 'Veracruz Tueste Americano',imagen : 3, productId : 'veracruz-tueste-americano'},
    {nombre : 'Veracruz Exportación ',    imagen : 4, productId : 'veracruz-tueste-americano'},
    {nombre : 'Descafeinado',             imagen : 5, productId : 'veracruz-tueste-americano'}
  ];*/

  ngOnInit() {
    this._coffeeService.getCoffees().subscribe(
      response => {
        if(response){
          this.coffees = response;
        }
        else{
        }
      },
      error => {
        console.log(error);
      }
    );
    window.scrollTo(0, 0);
  }

}
