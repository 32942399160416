import { Component, OnInit } from '@angular/core';
import { CartService } from '../services/cart.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css'],
})
export class CarritoComponent implements OnInit {

  public items;
  public total: number;

  public baseApiUrl: string = environment.apiUrl;

  constructor(
    private cartService: CartService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.items = this.cartService.getItems();
    this.getTotal();
  }

  incQuantity(id: string, molienda: string){
    this.items.forEach(item => {
      if(item.key.id == id && item.molienda == molienda) item.quantity++;
    });
    this.getTotal();
    this.cartService.setItems(this.items);
  }

  decQuantity(id: string, molienda:string){
    this.items.forEach(item => {
      if(item.key.id == id && item.molienda == molienda && item.quantity > 1) item.quantity--;
    });
    this.getTotal();
    this.cartService.setItems(this.items);
  }

  getTotal(){
    this.total=0;
    this.items.forEach(item => {
      this.total += item.quantity*item.key.coffee_price;
    });
  }

  descartar(id: string, molienda:string){
    this.cartService.discard(id, molienda);
    this.getTotal();
  }

}
