import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HablemosPanelData } from './hablemos-panel-data';

@Component({
  selector: 'hablemos-panel',
  templateUrl: './hablemos-panel.component.html',
  styleUrls: ['./hablemos-panel.component.css']
})
export class HablemosPanelComponent implements OnInit {

  @Input() data: HablemosPanelData;
  @Output() SetVideo = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  set(){
    this.SetVideo.emit({data : this.data});
  }

}
