import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class PurchaseService {

  private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ) { }

    getPurchases(): Observable<any> {
      return this._http.post(`${this._baseApiUrl}/purchase/getPurchases`, {user:localStorage.getItem('session_id')});
    }

    getPurchase(purchaseId): Observable<any> {
      return this._http.post(`${this._baseApiUrl}/purchase/getPurchase`, {purchase: purchaseId});
    }
}
