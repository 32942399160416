import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class FedexService {

  private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ){ }

    checkAddress(address):Observable<any>{
      return this._http.post(`${this._baseApiUrl}/fedex/checkAddress`, address);
    }

    getRate(address, weight):Observable<any>{
      return this._http.post(`${this._baseApiUrl}/fedex/getRate`, {address:address, weight:weight});
    }
}
