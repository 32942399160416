import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class SepomexService {

    private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ) { }

    estados(): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/sepomex/estados`);
    }

    municipios(estado): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/sepomex/municipios/` + estado);
    }

    cps(municipio): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/sepomex/cps/` + municipio);
    }

    colonias(cp): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/sepomex/colonias/` + cp);
    }

    deleteAddress(address): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/addresses/deleteAddress`, {address: address});
    }

    addAddress(address): Observable<any> {
        console.log(address);
        return this._http.post(`${this._baseApiUrl}/addresses/addAddress`, address);
    }

    updateAddress(address): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/addresses/updateAddress`, address);
    }

    getEstados(): Observable<any> {
        return this._http.get(`https://api-sepomex.hckdrk.mx/query/get_estados`);
    }

    getEstadosBack(): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/sepomex/getEstados`);
    }

    setEstados(estados): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/sepomex/registrarEstados`, estados);
    }

    getMunicipios(estado): Observable<any> {
        return this._http.get(`https://api-sepomex.hckdrk.mx/query/get_municipio_por_estado/` + estado.estado);
    }

    setMunicipios(estado, municipios): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/sepomex/registrarMunicipios/`, {estado, municipios});
    }

}
