import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class CoffeeService {

    private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ) { }

    getCoffees(): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/coffees/get`);
    }

    getCoffee(sku: string): Observable<any> {
        return this._http.get(`${this._baseApiUrl}/coffees/get/${sku}`);
    }
}
