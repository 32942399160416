import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.css']
})
export class MenuPanelComponent implements OnInit {

  @Input() data: PanelData;
  public flavors: any;
  public image: string;
  public img_title: string;
  public selected_flavor: string;

  constructor() {}

  ngOnInit() {
    this.flavors = this.data.subtitle.split('/');
    this.img_title = this.data.title.replace(/\s/g, '').replace(/á/g, "").replace(/é/g, "").replace(/í/g, "").replace(/ó/g, "").replace(/ú/g, "").replace(/\(/g, '').replace(/\)/g, '').toLowerCase();
    this.selected_flavor = this.flavors[0].replace(/\s/g, '').replace(/á/g, "").replace(/é/g, "").replace(/í/g, "").replace(/ó/g, "").replace(/ú/g, "").toLowerCase();
  }

  changeImage(flavor){
    this.selected_flavor = flavor.replace(/\s/g, '').replace(/á/g, "").replace(/é/g, "").replace(/í/g, "").replace(/ó/g, "").replace(/ú/g, "").toLowerCase();
  }

}
export class PanelData{
  image: string;
  title: string;
  subtitle: string;
  image_change: boolean;
}
