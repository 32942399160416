import { Component, OnInit } from '@angular/core';
import { SessionService } from '../services/session.service';
import { SepomexService } from '../services/sepomex.service';
import { CartService } from '../services/cart.service';
import { MailService } from '../services/mail.service';
import { Routes, RouterModule, Router } from '@angular/router';
declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  styleUrls: ['./datos.component.css'],
  providers: [MailService, SessionService, SepomexService]
})
export class DatosComponent implements OnInit {

  public userData:any;
  public userResp:any;

  public flag_userData:boolean;

  public editName: boolean;
  public editPhone: boolean;
  public editMail: boolean;

  public estados: any;
  public municipios: any;
  public cps: any;
  public colonias: any;

  public address: any;
  public addresses: any;
  public upAddress: any;

  public city_disabled: boolean;
  public cp_disabled: boolean;
  public neighbourhood_disabled: boolean;

  public errorMessage: string;
  public errorUpMessage: string;

  public tel_input_disabled = true;

  constructor(
    public _mailService: MailService,
    public _sessionService: SessionService,
    public _sepomexService: SepomexService,
    public _cartService: CartService,
    public _router : Router
  ) {
    this.errorMessage = '';
    this.errorUpMessage = '';
    this.editName = false;
    this.editPhone = false;
    this.editMail = false;
    this.estados = [];
    this.municipios = [];
    this.colonias = [];
    this.cps = [];
    this.addresses = [];
    this.city_disabled = true;
    this.cp_disabled = true;
    this.neighbourhood_disabled = true;
    this.address = {
      id: parseInt(localStorage.getItem('session_id')),
      address_name: '',
      address_state: '',
      address_city: '',
      address_cp: '',
      address_neighbourhood: '',
      address_street: '',
      address_num_int: '',
      address_num_ext: '',
    };
    this.upAddress = {
      id: parseInt(localStorage.getItem('session_id')),
      address_name: '',
      address_state: '',
      address_city: '',
      address_cp: '',
      address_neighbourhood: '',
      address_street: '',
      address_num_int: '',
      address_num_ext: '',
    };
  }

  ngOnInit(){
    window.scrollTo(0, 0);
    this._sessionService.checkSession(
      callback => {
        if(!callback.logged) this._router.navigate(['/home']);
      }
    );

    this._sessionService.getUserData().subscribe(
      response => {
        this.userData = response;
        this.flag_userData = true;
      },
      error => {
        console.log(error);
      }
    );
    this.userResp = this.userData;

    this._sepomexService.estados().subscribe(
      response => {
        this.estados = response;
      },
      error => {
        console.log(error);
      }
    );
    this._cartService.getAddresses().subscribe(
      response => {
        this.addresses = response;
      },
      error => {
        console.log(error);
      }
    );
  }

  check_tel(){
    var valid = true;
    for(var i = 0; i < this.userData.user_phone.length; i++){
      var l = this.userData.user_phone.charAt(i);
      if (l != 0 && l != 1 && l != 2 && l != 3 && l != 4 && l != 5 && l != 6 && l != 7 && l != 8 && l != 9){
        valid = false;
      }
    }
    if(!valid){
      alert('Teléfono inválido, vuelva a llenarlo');
      this.userData.user_phone = '';
    }
    else{
      if(this.userData.user_phone.length != 10) alert('El teléfono debe contener 10 dígitos')
      else this.tel_input_disabled = false;
    }
  }

  change_password(){
    this._mailService.restorePassword(this.userData.user_mail).subscribe(
      response => {
      },
      error => {
        if(error.error.text != 'OK') {
          alert("Ocurrió un error interno, intente de nuevo más tarde");
        }
      }
    );
  }

  deleteAddress(value){
    this._sepomexService.deleteAddress(value).subscribe(
      response => {
        this._cartService.getAddresses().subscribe(
          response => {
            this.addresses = response;
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  updateAddress(id){
    this.addresses.forEach(address => {
      if(address.id == id) this.upAddress = address;
    });
    this._sepomexService.municipios(this.upAddress.address_state).subscribe(
      response => {
        this.municipios = response;
        this.city_disabled = false;
        this._sepomexService.cps(this.upAddress.address_city).subscribe(
          response => {
            this.cps = response;
            this.cp_disabled = false;
            this._sepomexService.colonias(this.upAddress.address_cp).subscribe(
              response => {
                this.colonias = response;
                this.neighbourhood_disabled = false;
              },
              error => {
                console.log(error);
              }
            );
          },
          error => {
            console.log(error);
          }
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  onEdit(value){
    switch (value) {
      case 'name':
        if (this.editName) {
          this.editName = false;
          this.userData = this.userResp;
        }
        else this.editName = true;
        break;
      case 'phone':
        if (this.editPhone) {
          this.editPhone = false;
          this.userData = this.userResp;
        }
        else this.editPhone = true;
        break;
      case 'mail':
        if (this.editMail) {
          this.editMail = false;
          this.userData = this.userResp;
        }
        else this.editMail = true;
        break;
      default: break;
    }

  }

  onSave(){
    this._sessionService.updateUser(this.userData).subscribe(
      response => {
      },
      error => {
        console.log(error);
      }
    );
    this.editName = false;
    this.editPhone = false;
    this.editMail = false;
    this.userResp = this.userData;
  }

  getMunicipios(estado){
    this._sepomexService.municipios(estado).subscribe(
      response => {
        this.municipios = response;
        this.city_disabled = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  getCps(municipio){
    this._sepomexService.cps(municipio).subscribe(
      response => {
        this.cps = response;
        this.cp_disabled = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  getColonias(cp){
    this._sepomexService.colonias(cp).subscribe(
      response => {
        this.colonias = response;
        this.neighbourhood_disabled = false;
      },
      error => {
        console.log(error);
      }
    );
  }

  submitAddress(){
    if(this.address.name != ''){
      if(this.address.state != ''){
        if(this.address.city != ''){
          if(this.address.cp != ''){
            if(this.address.neighbourhood != ''){
              if(this.address.street != ''){
                this._sepomexService.addAddress(this.address).subscribe(
                  response => {
                    $('#address-form').modal('hide');
                    this.address = {
                      id: parseInt(localStorage.getItem('session_id')),
                      address_name: '',
                      address_state: '',
                      address_city: '',
                      address_cp: '',
                      address_neighbourhood: '',
                      address_street: '',
                      address_num_int: '',
                      address_num_ext: '',
                    };
                    this._cartService.getAddresses().subscribe(
                      response => {
                        this.addresses = response;
                      },
                      error => {
                        console.log(error);
                      }
                    );
                  },
                  error => {
                    console.log(error);
                  }
                );
              }
              else this.errorMessage = 'Debe ingresar una calle'
            }
            else this.errorMessage = 'Debe ingresar una colonia'
          }
          else this.errorMessage = 'Debe ingresar un código postal'
        }
        else this.errorMessage = 'Debe ingresar ua ciudad'
      }
      else this.errorMessage = 'Debe ingresar un estado'
    }
    else this.errorMessage = 'Debe ingresar un nombre a la dirección'
  }

  editAddress(){
    if(this.upAddress.name != ''){
      if(this.upAddress.state != ''){
        if(this.upAddress.city != ''){
          if(this.upAddress.cp != ''){
            if(this.upAddress.neighbourhood != ''){
              if(this.upAddress.street != ''){
                this._sepomexService.updateAddress(this.upAddress).subscribe(
                  response => {
                    this.errorUpMessage = 'Se actualizó la dirección'
                    this._sessionService.redirect('https://localhost:4200/datos');
                  },
                  error => {
                    console.log(error);
                  }
                );
              }
              else this.errorUpMessage = 'Debe ingresar una calle'
            }
            else this.errorUpMessage = 'Debe ingresar una colonia'
          }
          else this.errorUpMessage = 'Debe ingresar un código postal'
        }
        else this.errorUpMessage = 'Debe ingresar ua ciudad'
      }
      else this.errorUpMessage = 'Debe ingresar un estado'
    }
    else this.errorUpMessage = 'Debe ingresar un nombre a la dirección'
  }

}
