import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../services/purchase.service';
import { ActivatedRoute } from '@angular/router';
import { Routes, RouterModule, Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.css'],
  providers: [SessionService, PurchaseService]
})
export class CompraComponent implements OnInit {

  public purchase:any = [];
  public purchase_id:string;
  public items:any = [];
  public total:number = 0;
  public sendPrice:number;
  public address:any = [];
  public logged:boolean;
  public customer_info:any = [];

  public baseApiUrl: string = environment.apiUrl;

  constructor(
    public _sessionService: SessionService,
    public _purchaseService: PurchaseService,
    public _route: ActivatedRoute,
    public _router : Router
  ) {
     this.logged = false;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this._sessionService.checkSession(
      callback => {
        if(!callback.logged) this._router.navigate(['/home']);
        else {
          this.purchase_id = this._route.snapshot.paramMap.get('purchaseId');
          this._purchaseService.getPurchase(this.purchase_id).subscribe(
            response => {
              this.purchase = response;
              this.items = JSON.parse(response.purchase_items);
              this.address = JSON.parse(response.purchase_address);
              this.sendPrice = this.purchase.purchase_amount;
              this.items.forEach(item => {
                this.total += item.key.coffee_price * item.quantity;
              });
              this.sendPrice /= 100;
              this.sendPrice -= this.total;
              this.customer_info = response.purchase_customer;
            },
            error => {
              console.log(error);
            }
          );
        }
      }
    );
  }

}
