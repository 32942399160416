import { Component, OnInit } from '@angular/core';
import { HablemosPanelData } from '../hablemos-panel/hablemos-panel-data';

@Component({
  selector: 'app-hablemos-de-cafe',
  templateUrl: './hablemos-de-cafe.component.html',
  styleUrls: ['./hablemos-de-cafe.component.css']
})
export class HablemosDeCafeComponent implements OnInit {

  panels : HablemosPanelData[]= [
    {
      image : 'aeropress',
      title : 'Aeropress',
      subtitle : 'Infusión, extracción y filtración de café por presión de aire para obtener sabores semejantes a los de un café espresso',
      video: "_UBdYTsCRPo"
    },
    {
      image : 'chemex',
      title : 'Chemex',
      subtitle : 'Filtración de café por medio de un método que permite oxigenar la bebida para obtener sabores homogéneos y dulces, el sistema de este método se basa en la forma del reloj de arena.',
      video: "qxNWc6zkjc0"
    },
    {
      image : 'clever',
      title : 'Clever',
      subtitle : 'Método de infusión y filtración de café, con cuerpo medio que ofrece equilibrio.',
      video: "KNxEOd6U8tw"
    },
    {
      image : 'dripper',
      title : 'Dripper',
      subtitle : 'Método en forma de cono que por medio de filtración brinda sabores sutiles y elegantes en taza.',
      video: "Ki2L4LVw26U"
    },
    {
      image : 'prensa-francesa',
      title : 'Prensa francesa',
      subtitle : 'Infusión, extracción y filtración de café por medio de malla en un mismo método con intensidad y cuerpo alto.',
      video: "CtqFi5LUTc4"
    },
    {
      image : 'sifon-japones',
      title : 'Sifón',
      subtitle : 'Infusión, extracción y filtración al vacío con fuego directo. Un método atractivo a la vista basado en el matraz de bola usado para destilar, brinda sabores frutales en taza.',
      video: "GgYDeioyAbw"
    }
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  set(event){
    (<HTMLImageElement>document.getElementById('iframe')).src = "https://www.youtube.com/embed/"+event.data.video;
  }

}
