import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class MailService {

    private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ){ }

    send(id):Observable<any>{
      return this._http.post(`${this._baseApiUrl}/email/send`, {id:id});
    }

    sendOxxo(id, reference):Observable<any>{
      return this._http.post(`${this._baseApiUrl}/email/sendOxxo`, {id:id, reference:reference});
    }

    restorePassword(mail):Observable<any>{
      alert('Se enviará un correo de restauración a '+mail)
      return this._http.post(`${this._baseApiUrl}/email/resetPassword`, {mail:mail});
    }
}
