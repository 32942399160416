import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class SessionService {

    private _baseApiUrl: string = environment.apiUrl;

    constructor(
        private _http: HttpClient
    ) {
    }

    redirect(link) {
        return this._http.post(`${this._baseApiUrl}/dashboard/redirect`, {link});
    }

    getIdForRestore(hash){
      return this._http.post(`${this._baseApiUrl}/dashboard/getIdForRestore`, {hash:hash});
    }

    resetPassword(id,password){
      return this._http.post(`${this._baseApiUrl}/dashboard/resetPassword`, {id:id,password:password});
    }

    checkSession(callback){
      var response;
        var res = (callback) => {
          if(localStorage.getItem('session_id') != null && localStorage.getItem('session_id') != '' && typeof(localStorage.getItem('session_id')) != undefined){
            if(localStorage.getItem('session_token') != null && localStorage.getItem('session_token') != '' && typeof(localStorage.getItem('session_token')) != undefined){
                this._http.post(`${this._baseApiUrl}/dashboard/checkSession`,{session_id: localStorage.getItem('session_id'), session_token: localStorage.getItem('session_token')}).subscribe(
                response => {
                  return callback(response);
                },
                error => {
                  localStorage.setItem('session_id', '');
                  localStorage.setItem('session_token', '');
                  return callback({logged:false});
                }
              );
            }
            else {
              localStorage.setItem('session_id', '');
              localStorage.setItem('session_token', '');
              return callback({logged:false});
            }
          }
          else {
            localStorage.setItem('session_id', '');
            localStorage.setItem('session_token', '');
            return callback({logged:false});
          }
        }
        res(response => {return callback(response)})
    }

    getUserData(): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/dashboard/getUser`, {id: localStorage.getItem('session_id')});
    }

    registerUser(userData): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/dashboard/addUser`, userData);
    }

    updateUser(userData): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/dashboard/updateUser`, userData);
    }

    loginUser(userData): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/dashboard/login`, userData);
    }

    loginFacebook(userData): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/dashboard/addFacebookUser`, userData);
    }

    loginGoogle(userData): Observable<any> {
        return this._http.post(`${this._baseApiUrl}/dashboard/addGoogleUser`, userData);
    }

    logOut() {
      this._http.post(`${this._baseApiUrl}/dashboard/logout`, {id: localStorage.getItem('session_id')}).subscribe(
        response => {
        },
        error => {
          console.log(error);
        }
      );
      localStorage.setItem('session_id', '');
      localStorage.setItem('session_token', '');
    }
}
